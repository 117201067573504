/* animação futuras pros 3 pontinhos */

[data-teste] {
  opacity: 0;
  transition: 0.3s;
}

[data-anime="up"] {
  transform: translate3d(0, -50px, 0);
}

[data-anime].teste {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
