.cardInfo {
  display: flex;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0em 0.5em 0em;
}

.cardText1 {
  font-size: 0.688em;
  color: #555555;
}

.cardText2 {
  font-size: 2.25em;
  color: #ea3458;
}

.cardText3 {
  font-size: 1.125em;
  color: #ea3458;
  font-weight: bold;
}

.cardTitle {
  max-width: 4em;
}

.fristItem {
  max-width: 2em;
  height: 2em;
  background: url(/src/Assets/svg/first-position.svg) no-repeat;
}

.itemCard {
  font-weight: bold;
  font-size: 1em;
}
