.custom-shape-divider-top-1629206513 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-bottom: -166px;
}

.custom-shape-divider-top-1629206513 svg {
  width: 100%;
}
