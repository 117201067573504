.body {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
}

.boxCard {
  display: flex;
  margin: 1em 1em 0em 1em;
  border-bottom: 2px solid lightgray;
  max-width: max-content;
}

.titleCard {
  font-size: 1.2em;
  font-weight: 400;
  margin: 1em;
}

.legendCard {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 400;
  color: gray;
}
.legendCard1 {
  display: flex;
  justify-content: space-around;
  font-size: 0.9em;
  font-weight: 400;
  color: gray;
}

.legendCard2 {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin: 1em 0.4em 0em 1em;
}
