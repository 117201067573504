* {
  font-family: Roboto, sans-serif;
}
html,
body,
#root {
  height: 100% !important;
  min-height: 100%;
}
h2 {
  font-weight: 500;
  font-size: 1.1rem;
  color: #555555;
  margin-top: 10px;
  margin-bottom: 5px;
}
a,
button {
  cursor: pointer;
}
h3 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #353535;
  margin-bottom: 10px;
}

h5 {
  margin: 0;
  margin-left: 5px;
}

h6 {
  margin: 0;
  margin-left: 5px;
  font-weight: 400;
  padding-top: 5px;
  color: #555555;
}
p {
  font-weight: 400;
  font-size: 0.8rem;
  color: #353535;
}

a {
  text-decoration: none;
}
.title {
  font-size: 1rem;
}
.sub-title {
  font-size: 0.9rem;
}
.text {
  font-size: 0.8rem;
}
#produtos {
  display: flex;
  list-style: none;
  flex-direction: column;
}

#filtro {
  font-weight: 400;
  font-size: 0.8em;
  color: #ea3458;
}

.voltarLink {
  color: #ea3458;
  text-decoration: underline 1px #ea3458;
  background-color: transparent;
  border: none;
  font-weight: 700;
  margin-right: auto;
  padding: 0.5rem;
  cursor: pointer;
}

.MuiAutocomplete-root * {
  border-color: #fff !important;
  outline: 1px solid #fff !important;
}
.MuiButton-containedSecondary:hover {
  background-color: #ea3458 !important;
}
.MuiButton-label {
  font-size: 16px !important;
}
.MuiTab-wrapper {
  flex-direction: row !important;
}
.infinite-scroll {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  /* max-height: 630px; */
  z-index: 999;
  scroll-behavior: smooth;
}

.infinite-scroll-filter {
  flex: 1;
  -webkit-overflow-scrolling: touch;
  /* max-height: 630px; */
  z-index: 999;
  scroll-behavior: smooth;
}
.footer-column {
  display: flex;
  justify-content: center;
  flex-direction: Column;
  align-items: center;
  padding-bottom: 0.5rem;
}

.FilterTags {
  display: flex;
  overflow: scroll;
  border-left: 1px solid #353535;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar:not(.FilterTags) {
  scroll-behavior: smooth;
  display: none;
}

@supports (-webkit-touch-callout: none) and (min-device-width: 320px) and
  (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .infinite-scroll {
    max-height: 450px !important;
  }
  .page-align {
    z-index: 999;
  }
  .footer-column {
    display: block;
  }
}
.page-align {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.page-container {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
  box-shadow: 0px 12px 10px 5px #d9d9d9;
}

.MuiDialog-root {
  z-index: 9999 !important;
}

.ReactCrop__image {
  transform: scale(1) !important;
  max-height: 470px !important;

  /* padding-bottom: 200px!important; */
}

@media (max-width: 350px) {
  .ReactCrop__image {
    max-height: 350px !important;
  }
}
