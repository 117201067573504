.cardBox {
  display: flex;
  justify-content: space-around;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
  padding: 1em;
  margin: 1em 0em 1em 0em;
}

.cardInfo2 {
  display: flex;
}

.cardText1 {
  font-size: 0.688em;
  color: #555555;
  display: flex;
}

.cardText2 {
  font-size: 2.25em;
  color: #ea3458;
  font-weight: bold;
  display: flex;
}
